import React from 'react'
import { graphql } from 'gatsby'
import { Layout, HTMLRenderer, PageWrapper, CircularInfographics } from '@retina-packages/retina-theme-bootstrap'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import { reusableDataBuilders } from '@retina-packages/retina-theme-bootstrap/packages/helpers';
import { hcpDIRD } from './constants';
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import { deriveMultiLogo, deriveSiteMenus, footRefHTMLBuilder, fullHTMLDataBuilder, infographicDataBuilder } from '../../../utils';
import retinaConfig from '../../../utils/retinaConfigs';
import { HcpInheritedRetinalDisease, HcpMobileBanner, HcpPage } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle';
import { EsHeaderContainer } from '../../../css/common/style';
import { DiagnosingInheritedRetinal } from './styles';

const DiagnosingIRDTemplate = (props: Props) => {
  /**
   * Global section data building
   */
  const pgProp: PageQueryResult = props.data
  const blocksDIRD = pgProp.nodePage.relationships.field_paragraphs_gcso
  const validCOBlocks = blocksDIRD.filter((x: any) => 'relationships' in x)

  const pgContextsDIRD = props.pageContext
  const htmlStaticFilesDIRD = {
    nonSVGImages: pgContextsDIRD.nonSVGImages,
    svgMediaImages: pgContextsDIRD.svgMediaImages,
    allMediaDocument: pgContextsDIRD.documents
  }
  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: hcpDIRD.hcpDIRDMainMenu, siteFooterMenu: hcpDIRD.hcpDIRDFooterMenu, addHCPPrefix: retinaConfig.addHcpPrefix });
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  const bannerTitle = ""
  const carouselTitle = ""
  const footerTitle = hcpDIRD.hcpFooter
  const exitPopupTitle = hcpDIRD.hcpExitPopup
  const hcpSwitcherTitle = hcpDIRD.hcpSwitcher
  const topNavTitle = hcpDIRD.hcpTopNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({ blocks: validCOBlocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle });

  const siteLogos = deriveMultiLogo({blocks: validCOBlocks, title: hcpDIRD.hcpSiteLogo})
  const bannerHTML = fullHTMLDataBuilder({blocks: validCOBlocks, title: hcpDIRD.hcpDIRDBanner})
  const footerRefHTML = footRefHTMLBuilder({ blocks: validCOBlocks, title: hcpDIRD.hcpDIRDFootRef })

  /**
   * Page specific data building
   */
  const overlapHtml = fullHTMLDataBuilder({blocks: blocksDIRD, title: hcpDIRD.overLapDIRD})
  const goingBeyondHtml = fullHTMLDataBuilder({blocks: blocksDIRD, title: hcpDIRD.goingBeyondDIRD})
  const familyHistoryHtml = fullHTMLDataBuilder({blocks: blocksDIRD, title: hcpDIRD.familyHistoryDIRD})
  const { per, text1, text2 } = infographicDataBuilder({ blocks: blocksDIRD, title: hcpDIRD.infographicDIRD })
  const donutInfoGraData = {
    per: `${per}`,
    circular_text: text1,
    right_text: text2
  }
  const caseButtonHtml = fullHTMLDataBuilder({blocks: blocksDIRD, title: hcpDIRD.caseButtonDIRD})
  const earlyTestHTML = fullHTMLDataBuilder({blocks: blocksDIRD, title: hcpDIRD.earlyTestDIRD})

  return (
    <div className='hcppage'>
    <HcpPage>
      <HcpInheritedRetinalDisease className="hcpinretinal">
        <EsHeaderContainer>
          <HcpMobileBanner className="hcpmobilewrapper hcp-diagnosis-inherited-retinal-footer-wrapper">
            {props.pageContext !== null && props.pageContext.metaInfo !== null && (
              <MetaTagContainer metaData={props.pageContext.metaInfo} />
            )}
            <Layout
              title={"siteTitle"}
              modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
              location={props.location} data={mainMenu}
              mdata={footerMenu}
              footerData={footerText}
              audience={audience}
              footref={footerRefHTML}
              exitPopData={exitData}
              hcplinks={hcpSwitcher}
              topNavigation={topNavs}
              siteLogos={siteLogos}
              gtmBackToTopLabel={hcpDIRD.backToTopGTM}
              backToTopContent={retinaConfig.backToTopContent}
              staticMediaFiles={htmlStaticFilesDIRD}
              showRefContent={retinaConfig.showRefContent}
              hideRefContent={retinaConfig.hideRefContent}
              hcpValidate={retinaConfig.hcpValidate}
              preIndexUrl = {retinaConfig.preIndexUrl}
              hcpHomeUrl={retinaConfig.hcpHomeUrl}
              hcpPrefix={retinaConfig.hcpPrefix}
              siteHomeUrl={retinaConfig.siteHomeUrl}
              footerClassName={hcpDIRD.footerClassName}
              speechLangcode={retinaConfig.langConfig.speechLangcode}
              cpraFooter={true}
              cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
              cpraOT={true}
              cpraOTEnv={process.env.OT_ENV}
              cpraOTuuid={process.env.OT_UUID}
              {... {...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
            >
              <PageWrapper className='pagewrapper diagnosing-inherited-retinal-diseases'>
                <DiagnosingInheritedRetinal className="hcpinheritedretinal">
                <MobileBanner className="mobile-banner clinical-trials-banner">
                  <HTMLRenderer data={htmlStaticFilesDIRD} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
                </MobileBanner>
                  <div className='main-section o-top-space'>
                    <HTMLRenderer data={htmlStaticFilesDIRD} html={overlapHtml} />
                    <HTMLRenderer data={htmlStaticFilesDIRD} html={goingBeyondHtml} />
                    <div className="c-family--story inherited-retinal-disease-c-family mt-0 mb-0">
                      <div className="o-container">
                        <div className="c-family--story__inner o-border-gradient o-bottom-space infogrphic-color">
                          <HTMLRenderer data={htmlStaticFilesDIRD} html={familyHistoryHtml} />
                          <CircularInfographics
                              data={donutInfoGraData}
                              audience={audience} filedata={htmlStaticFilesDIRD}
                          />
                          <HTMLRenderer className='case-study-btn' data={htmlStaticFilesDIRD} html={caseButtonHtml}/>
                        </div>
                      </div>
                    </div>
                    <HTMLRenderer data={htmlStaticFilesDIRD} html={earlyTestHTML} simpleImage={true}/>
                  </div>
                </DiagnosingInheritedRetinal>
              </PageWrapper>
            </Layout>
          </HcpMobileBanner>
        </EsHeaderContainer>
      </HcpInheritedRetinalDisease>
    </HcpPage>
    </div>
  )
}

export default DiagnosingIRDTemplate

export const pageQuery = graphql`
  query ($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... DiagnosingIRDPageQuery
      }
    }
  }
`
