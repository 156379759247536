export const hcpDIRD: any = {
  hcpDIRDMainMenu: "Main Navigational Menu HCP GCSO - Retina Spain",
  hcpFooter: "Footer HCP - Retina Spain",
  hcpExitPopup: "Exit Popup - HCP - Retina Spain",
  hcpHCPSwitcher: "HCP Switcher Modal Pop Up - Retina Spain",
  hcpTopNav: "Top Navigational Menu HCP - Retina Spain",
  hcpSiteLogo: "Site logos - Retina Spain",
  hcpDIRDBanner: "Banner - HTML - IRD - HCP - Retina Spain",
  hcpDIRDFootRef: "Reference - IRD - HCP - Retina Spain",
  overLapDIRD: "overlapping and Accordion - IRD - HCP - Retina Spain",
  goingBeyondDIRD: "Going beyond the clinical - IRD - HCP - Retina Spain",
  familyHistoryDIRD: 'Family history - IRD - HCP - Retina Spain',
  infographicDIRD: "Infographic - IRD - HCP - Retina Spain",
  earlyTestDIRD: "Early testing matters - IRD - HCP - Retina Spain",
  caseButtonDIRD: "Read case study button - IRD - HCP - Retina Spain",
  backToTopGTM: "back to top - inherited retinal disease - hcp",
  footerClassName: "investigating-irds-footer"
}

const size = {
  mobilesmax: '320px'
}

export const deviceLocal = {
  mobilesMax: `(max-width: ${size.mobilesmax})`
}
